import type { InferOutput } from 'valibot'
import { orgNoSchema } from '#shared/organization-number'
import { array, intersect, literal, never, nullish, number, object, optional, picklist, string, union } from 'valibot'

export const CART_QUERY_KEY = ['core', 'ecommerce', 'cart']

export const cartStatusSchema = picklist([
  'NEW',
  'ACTIVE',
  'CHECKED_OUT',
  'DELETED',
  'FINISHED',
])

export type CartStatus = InferOutput<typeof cartStatusSchema>

export const productSourceSchema = picklist([
  'CRYSTALLIZE',
  'CRYSTALLIZE_KUNNE',
  'CRYSTALLIZE_SKOLE',
  'CRYSTALLIZE_JURIDIKA',
])

export type ProductSource = InferOutput<typeof productSourceSchema>

export const paymentSourceSchema = picklist(['INVOICE', 'VIPPS', 'TRIAL'])

export type PaymentSource = InferOutput<typeof paymentSourceSchema>

export const customerTypeSchema = picklist(['ORGANIZATION', 'USER'])

export type CustomerType = InferOutput<typeof customerTypeSchema>

export const cartItemDtoSchema = object({
  uuid: optional(uuidSchema), // Will always exist after the item has been created on the server
  amount: number(),
  productId: string(),
  productName: nullish(string()),
  subscriptionPlan: nullish(string()),
  subscriptionPeriod: nullish(string()),
})

export type CartItemDto = InferOutput<typeof cartItemDtoSchema>

export const cartDtoSchema = object({
  uuid: uuidSchema,
  userId: string(),
  cartStatus: cartStatusSchema,
  cartItems: array(cartItemDtoSchema),
  productSource: productSourceSchema,
})

export type CartDto = InferOutput<typeof cartDtoSchema>

export const subscriptionStartRequestSchema = intersect([
  object({
    buyerOrganizationNumber: string(),
    paymentSource: paymentSourceSchema,
    customerNumber: string(),
    customerOriginId: string(),
    customerType: customerTypeSchema,
    referenceNumber: string(),
  }),
  union([
    object({
      customerType: literal('ORGANIZATION'),
      customerOrganizationNumber: orgNoSchema,
      customerId: nullish(never()),
    }),
    object({
      customerType: literal('USER'),
      customerId: optional(string()),
      customerOrganizationNumber: nullish(never()),
    }),
  ]),
])

export type SubscriptionStartRequest = InferOutput<typeof subscriptionStartRequestSchema>

export type CartData = CartDto | null | undefined
