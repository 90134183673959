import { parse } from 'valibot'

/**
 * Composable for interacting with the eCommerce cart API.
 *
 * @see https://ecommerce-api.core.test.aschehoug.cloud/rest/swagger-ui/index.html#/carts
 */
export function useCartApi() {
  const { $coreApi } = useNuxtApp()

  const cartId = useState<UUID | null>('cartId', () => null)

  function requireCartId() {
    const idValue = unref(cartId)
    if (!idValue) {
      throw new Error('Missing cart ID')
    }
    return idValue
  }

  return {
    /**
     * Fetch the current cart. Creates a new cart if one doesn't exist yet.
     *
     * @param productSource - The source of the product. Defaults to `CRYSTALLIZE_SKOLE`.
     * @param signal - An optional AbortSignal to cancel the request.
     */
    async getOrCreateCart(productSource?: ProductSource, signal?: AbortSignal) {
      let search = ''
      if (productSource) {
        search = `?${new URLSearchParams({ productSource })}`
      }

      const res = await $coreApi.ecommerce<unknown>(`/carts${search}`, {
        signal,
      })

      const parsed = parse(cartDtoSchema, res)

      cartId.value = parsed.uuid

      return parsed
    },

    /**
     * Delete a cart by ID.
     */
    deleteCart() {
      return $coreApi.ecommerce<void>(`/carts/${requireCartId()}`, {
        method: 'DELETE',
      })
    },

    /**
     * Add a product to the cart.
     *
     * @param item - The product to add to the cart.
     *
     * @returns The updated cart.
     */
    async addItemToCart(item: Omit<CartItemDto, 'uuid'>) {
      const res = await $coreApi.ecommerce(`/carts/${requireCartId()}/item`, {
        method: 'POST',
        body: JSON.stringify(item),
      })

      return parse(cartDtoSchema, res)
    },

    /**
     * Update a product in the cart.
     *
     * @param item - The updated product.
     * @param item.uuid - The ID of the product to update.
     *
     * @returns The updated cart item.
     */
    async updateCartItem({ uuid, ...item }: CartItemDto) {
      const res = await $coreApi.ecommerce(`/carts/${requireCartId()}/item/${uuid}`, {
        method: 'PUT',
        body: JSON.stringify(item),
      })

      return parse(cartItemDtoSchema, res)
    },

    /**
     * Delete a product from the cart.
     *
     * @param itemId - The ID of the product to delete.
     *
     * @returns The updated cart.
     */
    async deleteItemFromCart(itemId: UUID) {
      const res = await $coreApi.ecommerce(`/carts/${requireCartId()}/item/${itemId}`, {
        method: 'DELETE',
      })

      return parse(cartDtoSchema, res)
    },

    /**
     * Start a subscription for the cart.
     *
     * @param request - The subscription start request.
     */
    startSubscriptions(request: SubscriptionStartRequest) {
      return $coreApi.ecommerce<void>(`/carts/${requireCartId()}/start-subscriptions`, {
        method: 'POST',
        body: JSON.stringify(request),
      })
    },
  }
}
