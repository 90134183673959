import type { Brand, InferOutput } from 'valibot'
import { brand, is, number, pipe, regex, string, transform, union } from 'valibot'

export const orgNoSchema = pipe(
  union([string(), number()]),
  transform(String),
  string(),
  // We have different sources for organization numbers. Some are prefixed with 'NO', some are not. We also allow special organization numbers starting with 'U', followed by 8 digits.
  regex(/^(?:NO)?(?:\d{9}|U\d{8})$/),
  brand('OrganizationNumber'),
)

export type OrganizationNumber = InferOutput<typeof orgNoSchema>

export type PrefixedOrganizationNumber = OrganizationNumber &
  Brand<'PrefixedOrganizationNumber'>

export type UnprefixedOrganizationNumber = OrganizationNumber &
  Brand<'UnprefixedOrganizationNumber'>

export function withPrefix(
  input: OrganizationNumber,
): PrefixedOrganizationNumber {
  return (
    input.startsWith('NO') || input.startsWith('U') ? input : `NO${input}`
  ) as PrefixedOrganizationNumber
}

export function withoutPrefix(
  input: OrganizationNumber,
): UnprefixedOrganizationNumber {
  return input.replace(/^NO/, '') as UnprefixedOrganizationNumber
}

export function isValidOrganizationNumber(
  input: unknown,
): input is OrganizationNumber {
  return is(orgNoSchema, input)
}
