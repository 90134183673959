import type { Product, ProductVariant } from '~/utils/crystallize/product'

export function isVariant(product: Product | ProductVariant): product is ProductVariant {
  return 'sku' in product && !!product.sku
}

export function getTitle(product: Product | ProductVariant) {
  if (isVariant(product)) {
    return product.components.title || product.name
  }
  else {
    const variant = getDefaultVariant(product)
    return variant?.components?.title
      || variant?.name
      || product.components.tittel
      || product.components.title
      || product.name
  }
}

export function getDefaultVariant(product: Product) {
  return product.variants.find(it => it.isDefault) ?? product.variants[0]
}

export function getProductCard(product: Product, cartItems?: CartItemDto[]) {
  const variant = getDefaultVariant(product)
  const cartItem = cartItems?.find(it => it.productId === variant?.sku)
  return {
    title: getTitle(product),
    subtitle: variant?.components.subtitle || product.components.subtitle,
    highlighted: product.components.highlighted,
    illustration: product.components.illustration?.[0],
    href: product.path,
    variant,
    isLastAdded: !!cartItems && !!cartItem && cartItems.indexOf(cartItem) === cartItems.length - 1,
    isInCart: !!cartItem,
  }
}

export type ProductCard = ReturnType<typeof getProductCard>
