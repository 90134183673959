import type { InferOutput } from 'valibot'
import { brand, pipe, string, uuid } from 'valibot'

export const uuidSchema = pipe(
  string(),
  uuid(),
  brand('UUID'),
)

export type UUID = InferOutput<typeof uuidSchema>
