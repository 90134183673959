import type { Product } from '~/utils/crystallize/product'

const ROOT_PATH = '/produktkatalog'

/**
 * Fetch a single product from the product catalogue.
 *
 * @param slug - The product's slug in Crystallize.
 */
export function useProduct(slug: MaybeRef<string>) {
  const fullPath = computed(() => `${ROOT_PATH}/${unref(slug)}`)

  const options = useCatalogueQueryOptions(fullPath)

  return useQuery({
    queryKey: options.queryKey,
    async queryFn() {
      const data = await options.queryFn()

      if (data.type !== 'product') {
        throw new Error('Expected product')
      }

      if (data.variants.length === 0) {
        throw new Error('No variants found')
      }

      return data
    },
  })
}

/**
 * Fetch all products from the product catalogue. Each item will in addition be cached on its own key, so that the data is available immediately if the user navigates to a product.
 */
export function useProductCatalogue() {
  const options = useCatalogueQueryOptions(ROOT_PATH)
  const version = useCrystallizeVersion()

  const queryClient = useQueryClient()

  return useQuery({
    queryKey: options.queryKey,
    async queryFn() {
      const data = await options.queryFn()

      if (data.type !== 'folder') {
        throw new Error('Expected folder')
      }

      const products: Product[] = []
      let highlighted: Product | undefined

      for (const product of data.children) {
        if (product.type !== 'product') {
          continue
        }

        if (product.variants.length === 0) {
          continue
        }

        const { queryKey } = useCatalogueQueryOptions(product.path, version)

        // Cache the product on its own key, so that it's available when the user navigates
        queryClient.setQueryData(unref(queryKey), product)

        const isHighlighted = data.components.highlighted?.some(it => product.variants.some(v => v.sku === it.sku))

        if (isHighlighted && !highlighted) {
          product.components.highlighted = true
          highlighted = product
        }
        else {
          if (isHighlighted) {
            console.error('Multiple highlighted products found, should be disallowed by min/max rule in Crystallize product shape.')
          }

          products.push(product)
        }
      }

      return {
        title: data.components.title,
        description: data.components.description,
        highlighted,
        products,
      }
    },
  })
}
