/**
 * Simple composable for retrieving the current cart state.
 */
export function useCart() {
  const { loggedIn } = useUserSession()

  const api = useCartApi()

  return useQuery({
    queryKey: CART_QUERY_KEY,
    enabled: loggedIn,
    queryFn: ({ signal }) => api.getOrCreateCart('CRYSTALLIZE_JURIDIKA', signal),
  })
}
